export default {
	termsAndConditions: {
		lastUpdate: 'Last updated: June 10th, 2024',
		content: `
		<p>The Terms you will find below are important because:</p>
		<ul>
			<li>They establish their legal rights in {siteName}.</li>
			<li>Explain the rights you grant us when you use {siteName}.</li>
			<li>
				They describe the rules that everyone must respect when using {siteName}.
			</li>
		</ul>
		<p>
			Please read these Terms carefully, as well as our {privacyPolicyLink} and any other
			documents mentioned in these Terms.
		</p>
		<p>Let's get started...</p>
		<h3>1. INTRODUCTION AND DATA OF {siteNameUpper}.</h3>
		<p>
			The website {websiteLink} (hereinafter, the "Website") is owned by the company
			{siteName} S.L (hereinafter, the "{siteName}"), A company
			incorporated in accordance with Spanish legislation with address at Avenida de
			Cerdanyola, nº 75, 1º, Sant Cugat del Vallès (Barcelona), holder of CIF number
			B-56.768.260 and registered in the Mercantile Registry of Barcelona in Volume
			49065, Folio 0033, Sheet B-606324, 1st Registration.
		</p>
		<p>
			{siteName} puts the Website at your disposal so that you can get to
			know us better, know the services we provide and if you wish, you can contact
			and, where appropriate, contract with us.
		</p>
		<h3>2. PURPOSE AND SCOPE OF APPLICATION OF THE TERMS OF USE</h3>
		<p>
			These conditions of use constitute, together with the {privacyPolicyLink} and the
			{cookiesPolicyLink}, the legal framework that regulates the use of the website
			{websiteLink}. These conditions of use will be available to you on the website
			in english so that you can consult, archive or print them at any time and
			whenever you want.
		</p>
		<p>
			To know any question regarding the treatment carried out by {siteName}.
			of your personal data, please visit our {privacyPolicyLink} and our {cookiesPolicyLink}.
			Both policies will also be available to you in english so that you can
			consult, archive or print them at any time.<br />
			{siteName} uses third-party cookies on the Website. To find out more
			about the type of cookies used on the web {websiteLink}, their purpose and how
			to configure your browser in case you do not want us to use these cookies,
			please visit our {cookiesPolicyLink}.
		</p>
		<p>
			Both these conditions of use, as well as the {privacyPolicyLink} and the 
			{cookiesPolicyLink} have been prepared in accordance with the provisions of current
			applicable regulations, among others, the Law 34/2002, on Services of the
			Information Society and Electronic Commerce and Regulation (EU) 2016/679 of
			the European Parliament and of the Council, of April 27, 2016, regarding the
			protection of natural persons with regard to to the processing of personal
			data and the free circulation of these data and by which Directive 95/46 / CE
			is repealed.
		</p>
		<h3>3. ACCESS TO THE WEBSITE AND ACCEPTANCE</h3>
		<p>
			Access to the Website is free except for the part related to the cost of the
			connection through the telecommunications network provided by the Internet
			access provider that you have contracted. You can freely browse the Website
			and access it as many times as you want. To access and navigate the Website,
			you do not need to register, except if you want to hire a service.
		</p>
		<p>
			The use of the website gives you the condition of user of the website
			(hereinafter, the "User") and implies your full and unreserved acceptance of
			these conditions of use, as well as the {privacyPolicyLink} and the {cookiesPolicyLink}
			of the Website. Furthermore, you agree not to use the Service for illegal
			purposes.
		</p>
		<p>
			By accessing the Website and browsing it, you declare under your
			responsibility that you are of legal age and that you have the necessary legal
			capacity to access the Website and use it, also declaring that you fully
			understand and accept these conditions of use without reservation.
		</p>
		<p>
			On the other hand, we inform you that for legal reasons we archive the
			electronic documents in which the purchases are formalized. You can access
			these documents at any time by requesting it at: {supportLink}.
		</p>
		<h3>4. CONDITIONS OF USE OF THE WEBSITE</h3>
		<p>
			The User agrees to use the Website in accordance with these conditions of use,
			current legislation and good faith. Thus, the User will respond to {siteName}.
			 and before any third party for the damages and losses derived from
			the use contrary to these conditions, the legislation and the good faith made
			by the User of the Website.
		</p>
		<h3>5. DESCRIPTION OF THE SERVICE</h3>
		<p>
			Through the Service, {siteName} allows subscription to the service to
			carry out image editing.
		</p>
		<p>
			The language in which the contract between {siteName} and the buyer
			will be concluded will be that of the country of origin.<br />
			The purchase process in the online service will be as follows:
		</p>
		<ol>
			<li>You must register on the website with a username and password.</li>
			<li>
				At the time of registration, the user must select one of the payment plans
				found in the Prices section and will be granted credits depending on the
				selected plan. Each credit will allow you to edit one image.
			</li>
			<li>
				After the purchase of the service, the user will have an administration
				panel where they will find:
				<ul>
					<li>
						A section with a list of all the images that have been processed, being
						able to edit it as many times as you want and download it to your
						device.
					</li>
					<li>
						A section of Plans and payments to consult the invoices and cancel the
						subscription.
					</li>
					<li>A section of My Account to be able to change account preferences.</li>
				</ul>
			</li>
		</ol>
		<p>
			Once the purchase of the service has been made, it will be confirmed by
			sending an email, within a maximum period of 24 hours, to the address you have
			indicated. It will indicate the service, how to cancel the subscription, its
			amount, applicable taxes, the form of payment and the applicable terms and
			conditions.
		</p>
		<h4>5.1 Subscription</h4>
		<p>
			The service subscription varies depending on the selected plan and will be
			automatically renewed for the same period of time until its termination.
			Unless you cancel your subscription, you authorize us to load the
			corresponding annual subscription fee in your payment method.
		</p>
		<p>
			You can cancel your subscription to the Service at any time, and you will
			continue to have access to it until the end of your annual billing period. To
			the extent permitted by law, payments are non-refundable and we do not provide
			refunds or credits for partial annual subscription periods.
		</p>
		<h3>6. PRICE AND TAXES</h3>
		<p>
			The prices of the products offered on {siteName} are indicated in euros
			(€) or the currency of the country from which the purchase is made, the Value
			Added Tax (VAT) in force at the time of purchase is levied on the price of the
			products.
		</p>
		<p>
			In any case, the operation could be exempt or not subject to VAT depending on
			your country of residence or the condition in which you act. For this reason,
			in some cases the final price of the order may be altered with respect to the
			one shown.<br />
			Purchases made by subjects exempt from VAT payment will indicate this by
			sending their VAT/NIF number by email to {supportLink}, not recording
			purchases with said tax.
		</p>
		<h3>7. PAYMENT METHOD</h3>
		<p>
			Online payment of the purchased service can be made by credit or debit card.
			In this sense, {siteName} informs credit and debit card holders that
			transactions in the online store are carried out through a secure payment
			gateway, using SSL technology to guarantee the security of data transmission.
		</p>
		<h3>8. RIGHT OF WITHDRAWAL AND REFUNDS</h3>
		<h4>8.1. Right of withdrawal</h4>
		<p>
			Given the type of product marketed in our online service, software in digital
			format of QR, the right of withdrawal will not be applicable according to
			article 103, letter m) of Royal Legislative Decree 1/2007, of November 16, by
			the that the revised text of the General Law for the Defense of Consumers and
			Users and other complementary laws is approved.
		</p>
		<h4>8.2. Refunds</h4>
		<p>
			Regardless of the plan chosen by the user, the amounts paid by the user and to
			the extent permitted by law will not be refundable.
		</p>
		<p>
			The contracted service may not be assigned or transferred to other users of
			the Service.
		</p>
		<p>
			In any case, the user may make use of the contracted service until the end of
			the contracted period.
		</p>
		<h3>9. VALIDITY OF OFFERS</h3>
		<p>
			The services offered in the Service, and their prices, will be available for
			purchase while they are in the catalog of services displayed through this
			website.
		</p>
		<p>
			In any case, {siteName} reserves the right to make the modifications it
			deems appropriate in the Service, being able to update products and services
			depending on the market.
		</p>
		<p>
			{siteName} reserves the right to change prices without prior notice. We
			inform you that despite the updates that are made to the prices of the
			Service, they could contain errors. We will promptly correct any errors that
			appear, but they will not be binding on the Service.
		</p>
		<h3>10. SAFETY</h3>
		<p>
			As indicated above, the entire purchase procedure, as well as the transmission
			of your personal data and payment systems, is carried out on a secure page and
			in an encrypted way through the SSL protocol.
		</p>
		<p>
			We guarantee the security of the Service in accordance with current
			technological knowledge. However, {siteName} cannot guarantee the full
			future security of the Service. In any case, we do promise to correct and
			implement the appropriate corrective measures to correct a possible security
			breach as soon as possible.
		</p>
		<p>
			You agree to notify {siteName}, immediately and via email {supportLink}
			of any situation that could lead to the impersonation of a user's identity.
		</p>
		<h3>11. MODIFICATIONS AND NULLITY</h3>
		<p>
			We may update the terms and conditions of the Service in the future, as well
			as the features and functions of the Service itself.
		</p>
		<p>
			We will inform you of the changes in terms and conditions by placing a notice
			in a prominent place on our website and/or by email.
		</p>
		<p>
			If any clause included in these terms and conditions is declared, totally or
			partially, null or ineffective, it will only affect said provision or the part
			of it that is null or ineffective. The terms and conditions will subsist in
			everything else, having such provision, or the part of it that is affected, by
			not being placed.
		</p>
		<h3>12. CLAIMS AND ACTIONS DERIVED FROM THE CONTRACT</h3>
		<p>
			As far as possible, this Service is governed by Spanish law.<br />
			In order to simplify the resolution of claims through civil proceedings and
			reduce costs, we do not exclude the possibility of submitting ourselves to an
			Equity Arbitration of the Court of Arbitration of the Chambers of Commerce and
			Industry. In this sense, and according to the applicable regulations,
			 {siteName} informs of the existence of a European online dispute resolution
			platform that facilitates the out-of-court resolution of such disputes for
			contracts also concluded online between consumers and Internet service
			providers. This platform can be accessed through the following website:
			<a href="http://ec.europa.eu/odr" target="_blank">http://ec.europa.eu/odr</a>
		</p>
		<h3>13. INTELLECTUAL AND INDUSTRIAL PROPERTY RIGHTS</h3>
		<p>
			The Website and its contents are subject to protection in application of
			current national regulations on Intellectual and/or Industrial Property, as
			well as in application of the international conventions applicable in such
			matters. {siteName} is the owner or has duly licensed all the
			Intellectual and Industrial Property rights on the Website and its contents.
		</p>
		<p>
			Any form of reproduction, distribution, public communication, transformation,
			making available and, in general, any other act of exploitation whether or not
			for commercial purposes of the Website or its contents, without the prior
			express consent of {siteName}. It is expressly forbidden to erase the
			identifying data of {siteName} as the owner of the Intellectual and
			Industrial Property rights of the Website or its contents.
		</p>
		<p>
			{siteName} reserves all Intellectual and Industrial Property rights
			over the Website and its contents, also reserving the exercise of any legal
			actions that correspond to it in order to cease, prevent or prosecute any
			illicit use of the Website or its contents. In no case and under no
			circumstances does access to the Website or its use by the User or third
			parties constitute or imply an assignment of any kind by {siteName} of
			the Intellectual or Industrial Property rights of the Website or its contents.
			In no case the lack of requirement by {siteName} to cease use of the
			Website or its illegal or content contrary to these conditions may be
			understood as tacit consent by {siteName} to such uses or as a waiver
			of the exercise of any legal action that may correspond to it.
		</p>
		<h3>14. RESPONSIBILITIES AND GUARANTEES</h3>
		<p>
			The User must use the Website and its contents in accordance with the
			provisions of these conditions of use, current legislation and good faith. Any
			use of the website or its contents contrary to these general conditions, the
			law or good faith is expressly prohibited, regardless of whether said use has
			an economic purpose or not.<br />
			The User will be responsible for any damage or harm caused to {siteName}
			 or to third parties derived directly or indirectly from any use
			that the User makes of the Website or its contents contrary to these
			conditions, the law, morality, public order or good faith.
		</p>
		<p>
			For the Website and its contents to be accessible to the User, {siteName}
			 requires services provided by third parties. It is possible that
			despite the efforts made by {siteName} to keep the Website available
			and accessible to users, its availability may be suspended, interrupted or
			canceled for reasons beyond {siteName}. That is why {siteName}
			does not guarantee the absence of interruptions or errors in accessing the
			Website or its contents, nor that they are updated or always displayed in
			their latest version. Consequently, {siteName} declines any
			responsibility for damages that may be derived to the User or third parties as
			a result of the suspension, interruption, lack of continuity, network outage
			or disconnection of the Website.
		</p>
		<p>
			In no case and under no circumstances will {siteName} be liable for
			damages or losses derived to the user from causes not attributable to {siteName}
			 such as, by way of example and in no case limiting, natural
			disasters, situations of force majeure or extreme urgency, viruses, harmful
			components, browser malfunctions, network drops, computer intrusions or
			third-party attacks.
		</p>
		<h3>15. LINKS</h3>
		<p>
			The websites, platforms, forums, chats, blogs or external social networks that
			link or link to the Website must clearly inform that they do not have the
			authorization or supervision of {siteName} and that said linking or
			link does not entail, suppose or imply association of any kind between {siteName}
			 and the websites, platforms, forums, chats, blogs or external
			social networks that link or link to the Website.
		</p>
		<p>
			In any case, any link that is destined for the Website must be directed
			directly to the home page or home page of the Website by clicking on the URL
			of the website covering the entire screen of the home page or page of start of
			the Website, so that the link is absolute and complete without the use of
			frames, frames or hyperlinks. {siteName} does not respond in any case
			and under any circumstances to the content, information or data related to the
			website, its content or any other matter contained in the websites, platforms,
			forums, chats, blogs or third-party social networks that link or link to the
			Website.
		</p>
		<p>
			It is expressly forbidden to link or link to the Website from websites,
			platforms, forums, chats, blogs or social networks contrary to the law, morals
			and good customs, especially those with pornographic, illicit, illegal or
			infringing human rights content.
		</p>
		<p>
			On the other hand, the Website may contain links to third party websites,
			platforms, forums, chats, blogs or social networks. {siteName} does not
			have the human and/or technical means to know or control the information and
			content that appear on third-party websites. Therefore, in no case will {siteName}
			 be responsible for the content of the linked sites and under no
			circumstances may said links be considered effective knowledge of the content
			of the sites to which they link.
		</p>
		<h3>16. DATA PROTECTION</h3>
		<p>
			As established in its {privacyPolicyLink}, {siteName} undertakes to use User
			data, to respect its confidentiality and to use it in accordance with the
			purpose of the treatment, as well as to comply with its obligation to save it.
			and adapt all measures to avoid alteration, loss, treatment or unauthorized
			access in accordance with the provisions of current legislation regarding the
			protection of personal data.
		</p>
		<p>
			The Website incorporates appropriate safeguards and uses industry standard
			technology to improve the security, integrity, completeness, accuracy and
			protection of the personal data it has collected, and has established
			reasonable precautions to protect that information from possible loss or
			misuse. Its rules on data protection and security are periodically reviewed
			and improved when necessary, and it strives to ensure that only authorized
			persons can access the information provided by the User.
		</p>
		<p>
			To learn more about the management and treatment carried out by {siteName}
			 of your data, please read our {privacyPolicyLink} and our {cookiesPolicyLink}.
		</p>
		<h3>17. DURATION AND MODIFICATION</h3>
		<p>
			These conditions of use will be in force for as long as they are exposed to
			users, so that as soon as they are totally or partially modified or updated,
			these conditions will cease to be in force, being the new conditions of use
			those that go on to regulate the use of the Website.
		</p>
		<p>
			{siteName} reserves the right to review, modify or update these
			conditions of use at any time. In the event of any change in these conditions
			of use for any reason, {siteName} will notify users on the home page of
			the web about said circumstance so that they are duly informed and can consult
			the revisions, modifications or updates of the present conditions of use to
			your satisfaction.
		</p>
		<h3>18. GENERAL</h3>
		<p>
			In no case and under no circumstances may the lack of exercise of your rights
			by {siteName} be understood as a waiver of them.
		</p>
		<p>
			The headings contained in these conditions are merely indicative or
			informative, so that they do not affect, qualify or expand the interpretation
			thereof.
		</p>
		<p>
			In the event that any of the clauses contained in these conditions were
			declared null, abusive or unenforceable, totally or partially, by a judicial
			or administrative body, said declaration will individually affect the clause
			in question, taking it as not and without the declaration of nullity, abuse or
			inapplicability being extended under any circumstances to the rest of the
			conditions, which will subsist.
		</p>
		<p>
			These conditions of use, as well as the {privacyPolicyLink} and the {cookiesPolicyLink}
			are governed by current Spanish regulations at all times.
		</p>
		<h3>19. CUSTOMER SERVICE</h3>
		<p>
			For any clarification, incident or claim, you can contact us through:<br />
			E-mail: {supportLink}<br />
			Postal address: Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain.
		</p>`
	},
	privacyPolicy: {
		lastUpdate: 'Last updated: June 10th, 2024',
		content: `
			<h3>1. Responsible party and description</h3>
			<h5>1.1. Responsable</h5>
			<p>The person responsible for the treatment of this site is Remove Background S.L (hereinafter Remove Background 
			or the Service), with address at Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain.</p>
			<p>NIF: B56768260<br> Email: {supportLink}</p>

			<h5>1.2. Description</h5>
			<p>Acceptance of the privacy policy of Remove Background (hereinafter "Privacy Policy"), is a necessary condition 
				for the use of our website and the provision of the service (hereinafter the "Service").<br>
				This Privacy Policy regulates the collection, treatment and use of your personal and non-personal information 
				as a user of Remove Background, as of the effective date that appears in the header.<br>
				To process your personal data, Remove Background complies with current local and European legislation, 
				as well as its implementing regulations.</p>


			<h3>2. Information collected</h3>
			<p>Access to our website can be done without the need to provide any information, although to register and / 
				or contract our plans you will need to provide us with some personal information. The information (personal 
				or non-personal) collected by the Service may vary based on that.<br>
				The personal and non-personal information collected by the Service will come to us in three ways: 1) that which 
				is collected automatically 2) that which you provide us voluntarily and 3) that which is provided by third parties.</p>

			<h5>2.1. The one collected automatically</h5>
			<p>This information will consist of:</p>
			<ul>
				<li>The one collected through cookies or similar mechanisms stored on your device, always with your consent or in 
					accordance with other legal bases. Check our Cookies Policy for more information.</li>
				<li>The IP from which the connection is made, the type of device used and its characteristics, the version of 
					the operating system, the type of browser, the language, the date, the country, the time of the request, 
					the URL from the that you come from, the URL to which you go or the mobile network used, among others.</li>
				<li>Service use data and possible errors detected during its use.</li>
				<li>In addition, Remove Background uses Google Analytics, an analytical service provided by Google, LLC. 
					domiciled in the United States with headquarters at 1600 Amphitheater Parkway, Mountain View, California 94043. 
					For the provision of these services, they use cookies that collect information, including the user's IP address, 
					which will be transmitted, processed and stored by Google in the terms established on the web www.google.com. 
					Including the possible transmission of said information to third parties for reasons of legal requirement or 
					when said third parties process the information on behalf of Google.</li>
			</ul>
			<p>In any case, you can disable Google Analytics cookies from <a href="{externalLink: disableGoogleAnalyticsLink}">here</a>.</p>

			<h5>2.2. The one you voluntarily provide</h5>
			<p>This information will consist of:</p>
			<ul>
				<li>All data is mandatory.</li>
				<li>The one required by Remove Background to complete your registration on the web: name and surname, address and email, among others.</li>
				<li>All data is mandatory.</li>
				<li>The information, personal or not, to complete the contracting of the service such as your email address and your credit or debit card number.</li>
				<li>The information, personal or not, that the messages sent through the contact or opinion channels established in the Service may contain, 
					for example your name and email.</li>
			</ul>

			<h5>2.3. The one provided by third parties</h5>
			<p>This information will consist of:</p>
			<ul>
				<li>The one provided by payment systems or credit card processors, such as the time of purchase or its amount.</li>
			</ul>


			<h3>3. Rights and purposes</h3>
			<p>We inform you that the completion of the forms is voluntary. However, if you do not fill in the required fields 
				(usually marked with an asterisk), the use of some functions of the Service will not be possible or will be limited.<br>
				The personal data that you provide us will be incorporated and processed in the Registry of Treatment Activities of Remove Background 
				in order to be able to attend to your requests, provide the requested service and keep you informed about matters related to the activity 
				of the company and its services.<br>
				You can exercise at any time the rights of access, rectification, deletion, limitation of your treatment, 
				opposition and portability of your personal data by email addressed to: {supportLink} or the postal address: 
				Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain.<br>
				In both cases, you must identify yourself with your name and surname, as well as a copy of your DNI or national ID, if necessary.<br>
				In the event that you have granted consent for a specific purpose, you have the right to withdraw consent at any time, 
				without affecting the legality of the treatment based on the consent prior to its withdrawal.<br>
				In addition, if you consider that there is a problem with the way in which Remove Background is handling your data, 
				you can direct your complaints to the <a href="{knowYourRightsLink}">data protection authority</a> that corresponds, being the <a href="{aepdLink}">Spanish Agency for Data Protection</a> 
				the one indicated in the case of Spain.</p>


			<h3>4. Age</h3>
			<p>Regarding the use of the online service, you declare that you are of legal age and that you have the necessary legal 
				capacity to be bound by this agreement and use the site in accordance with its terms and conditions, which you fully 
				understand and acknowledge.<br>
				You declare that all the information you provide to access the Service, before and during its use, is true, complete 
				and accurate.</p>


			<h3>5. Use of data</h3>
			<p>Remove Background will use the collected data to:</p>
			<ul>
				<li>Manage and update the Service (the legal basis being our legitimate interest in maintaining and keeping the Service 
					up to date and in good condition).</li>
				<li>Address the questions you raise (the legal basis being our legitimate interest in attending to the requests and 
					doubts of our users).</li>
				<li>Process the payments you make (the contract being the legal basis).</li>
				<li>Provide the contracted service (the contract being the legal basis).</li>
				<li>Maintain the security of the Service, investigate illegal activities, enforce our terms and conditions and
					help the state security forces in the framework of their possible investigations (the legal basis being our 
					legitimate interest in guaranteeing and maintaining the security of the Service and its users).</li>
			</ul>
			<p>Remove Background does not use automated decisions, although it can generate basic user profiles, 
				the legal basis being our legitimate interest for commercial purposes and providing personalized offers.<br>
				Also, Remove Background may use user information in the form of aggregated and anonymous data to show it to third parties. 
				You may also share statistics and demographic information about users and their use of the Service with third parties. 
				But none of this will allow those third parties to personally identify you.</p>

			<h5>5.1. In emails and contact forms</h5>
			<p>The website has an SSL encryption that allows the user to send their personal data securely through standard contact forms.<br>
				The personal data collected will be subject to automated processing and incorporated into the corresponding files 
				of the record of processing activities and of which Remove Background is the owner.</p>
			<p>In that sense:</p>
			<ul>
				<li>We will receive your IP address, which will be used to verify the origin of the message in order to offer you adequate 
					recommendations (for example, present the information in the correct language) and to detect possible irregularities 
					(for example, possible attempts to cyberattack the Service), as well as data related to your ISP.</li>
				<li>Likewise, you can provide us with your data by phone, email and other indicated means of communication.</li>
			</ul>
			<p>Regarding the means of payment used:</p>
			<ul>
				<li>Our payment service providers to pay for the provision of our services are credit or debit card. 
					At no time will the Service or its staff have access to the bank details (for example, the credit card number) 
					that the user as a customer will provide to those third parties.</li>
			</ul>


			<h3>6. Data retention</h3>
			<p>Below, they are indicated for how long the data processed by the Service is kept:</p>
			<ul>
				<li>The disaggregated data will be kept without a deletion period.</li>
				<li>
					Customer data will be kept for the minimum necessary, and may be kept until:
					<ul>
						<li>5 years, according to art. 1964 of the Civil Code (personal actions without special term).</li>
						<li>6 years, according to art. 30 of the Commercial Code (accounting books or invoices, for example).</li>
					</ul>
				</li>
				<li>User data will be kept for a maximum period of 12 months to be able to attend to their requests.</li>
			</ul>


			<h3>7. Data shared with third parties</h3>
			<p>There are third parties that manage part of the Service.<br>
				Remove Background requires them to comply with this Privacy Policy as applicable, in addition to having their own. However, Remove Background will not be responsible for compliance with said policy.<br>
				Under some circumstances, Remove Background may share, use, or preserve with third parties any of the personal information collected:</p>
			<ul>
				<li>To provide the Service:</li>
			</ul>
			<p>Service providers that perform functions on our behalf, such as payment services, web hosting, business analytics, customer service, or web marketing. These service providers may collect and have access to the information that is necessary for them to perform their functions, but they are not allowed to share or use the information for any other purpose.</p>
			<ul>
				<li>To cooperate with the competent authorities:</li>
			</ul>
			<p>If we believe it is reasonably necessary to satisfy any law, legal process or legitimate interest. In any case, we will only provide the information strictly required.</p>


			<h3>8. Security measures</h3>
			<p>Remove Background adopts all the technical and organizational measures necessary to
				protect the security and integrity of personal and non-personal information collected. 
				Both against unauthorized access and its accidental alteration, loss or destruction.<br>
				In any case, Remove Background cannot guarantee the absolute security of the information collected, 
				so you must collaborate and use common sense about the information shared at all times.<br>
				You understand and acknowledge that, even after deletion, personal and non-personal information may 
				remain visible in cache or if other users have copied or stored it.</p>


			<h3>9. Changes to the Privacy Policy</h3>
			<p>We may update this Privacy Policy in the future. We will inform you of its changes by sending a notice to the email address provided and / or by placing a notice in a prominent place on our website.</p>


			<h3>10. Contact</h3>
			<p>If you have questions about this Privacy Policy, contact us at:</p>
			<p>E-mail: {supportLink}<br> 
				Address: Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain.</p>
		`
	},
	gdpr: {
		lastUpdate: 'Last updated: June 10th, 2024',
		content: `
			<h3>1. Who is responsible and how to contact them?</h3>
			<p>The controller of this site is Remove Background S.L with address at Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain.</p>
			<p>You can contact him via email at {supportLink}.</p>

			<h3>2. What data is collected?</h3>
			<p>
				We automatically collect data through the cookies that we load on your device, including analytics. 
				We also collect the IP from which the connection is made, the type of device used and its characteristics, 
				the version of the operating system, the type of browser, the language, the date, the country, the time of the request, 
				the URL from which you come from, the URL to which you go or the mobile network used, among others.<br>
				Voluntarily the user provides us with some information such as his name and surname, email or bank information 
				to be able to contact us, sign up for the service or purchase a subscription.
			</p>

			<h3>3. What is the collected data used for?</h3>
			<p>
				The Service will use the data collected, among other things, to administer and update the service, respond to your requests, 
				send you our newsletter and promotional offers by email, manage your registration on the platform, manage and provide 
				the services related to your subscription, process the payments you make and issue the corresponding invoices, 
				maintain the security of the Service, investigate illicit activities, enforce our terms and conditions and 
				help the state security forces and bodies in the framework of their eventual investigations.
			</p>

			<h3>4. How long are they kept?</h3>
			<ul>
				<li>The disaggregated data will be kept without a deletion period.</li>
				<li>
					Customer data will be kept for the minimum necessary, and may be kept until:
					<ul>
						<li>5 years, according to art. 1964 of the Civil Code (personal actions without special term).</li>
						<li>6 years, according to art. 30 of the Commercial Code (accounting books or invoice, for example).</li>
					</ul>
				</li>
				<li>User data will be kept for a maximum period of 12 months in order to attend to their requests.</li>
				<li>User data uploaded by the Service to pages and profiles on social networks will be kept from the moment the user offers his consent until he withdraws it.</li>
			</ul>

			<h3>5. What are your rights as a user?</h3>

			<p>
				Right of access: The affected party shall have the right to obtain confirmation from the data controller as to whether 
				or not personal data concerning him or her is being processed.
			</p>
			<p>
				Right of rectification: the affected party shall have the right to obtain, without undue delay, from the data controller 
				the rectification of personal data that is inaccurate and that affects him directly. Likewise, the interested party will 
				have the right to complete incomplete personal data, including by means of an additional declaration.
			</p>
			<p>
				Right of deletion: It is the right of the affected party to obtain, without undue delay, from the data controller the deletion 
				of the personal data that concerns him.
			</p>
			<p>
				Right to limit data processing: consists in obtaining the limitation of the processing of your data by the person in charge, 
				although its exercise has two aspects: 1) When you challenge the accuracy of your personal data, during a period that allows 
				the person in charge to verify it and 2) When you have objected to the processing of your personal data that the person in charge 
				carries out based on legitimate interest or a mission of public interest, while the person in charge verifies whether these reasons 
				prevail over yours.
			</p>
			<p>
				Right to data portability: It is the right of the affected party to receive the personal data that concerns him, 
				that he has provided to a data controller, in a structured format, of common use and mechanical reading, and 
				to transmit them to another controller without being prevented by the controller to whom they were provided.
			</p>
			<p>
				In this sense, the interested party will have the right to have personal data transmitted directly from controller 
				to controller when technically possible.
			</p>

			<h3>6. How can you exercise your rights?</h3>

			<p>
				You can exercise your personal data rights at any time by sending an email to: {supportLink} Or the postal address: 
				Av. Cerdanyola, 75 - 1º, CP 08172, Sant Cugat del Vallés, Barcelona, Spain. In both cases, you must identify yourself with your name and surnames, 
				as well as a copy of your DNI or national ID, if necessary.<br>
				In addition, if you consider that there is a problem with the way Remove Background is handling your data, you can direct your claims 
				to the <a href="{knowYourRightsLink}">data protection authority</a> that corresponds, being the <a href="{aepdLink}">Spanish Agency for Data Protection</a> 
				the one indicated in the case of Spain.
			</p>

			<h3>7. Are security measures adopted?</h3>
			<p>
				The Service takes all necessary technical and organizational measures to protect the security and integrity of personal 
				and non-personal information collected. Both against unauthorized access and accidental alteration, loss or destruction.<br>
				In any case, the Service cannot guarantee the absolute security of the information collected, so you must collaborate and 
				use common sense at all times regarding shared information.<br>
				You understand and acknowledge that, even after deletion, Personal and Non-Personal Information may remain viewable in cache or 
				if copied or stored by other users.
			</p>

			<h3>8. Are there transfers of personal data or international transfers?</h3>

			<p>
				We do not transfer data or international transfers. However, there are third parties that manage part of the Service 
				that we require to comply with our Privacy Policy insofar as it is applicable to them and that they have their own. 
				These third parties are service providers that perform functions on our behalf, such as web hosting, 
				analytics monitoring, or web development.<br>
				Also, if we believe that it is reasonably necessary to satisfy any law, legal process or legitimate interest. 
				In any case, we will only provide the strictly required information.
			</p>

		`
	},
	cookiesPolicy: {
		lastUpdate: 'Last updated: June 21th, 2024',
		content: `
			<h3>Summary</h3>
			<ul>
			    <li>Cookies used: <strong>Own and Third-Party</strong></li>
			    <li>Are they used to obtain statistics? <strong>Yes, based on our legitimate interest in better managing the service.</strong></li>
			    <li>Third-party services that install cookies through this website: <strong>Google, Google Analytics, Cloudflare, and Stripe.</strong></li>
			</ul>

			<h3>How to configure and disable cookies</h3>
			<p>
			    You can allow, block, or delete the cookies installed on your device from your web browser. If you do not allow cookies to be installed on your device, 
			    the browsing experience may worsen. Below, you can find how to configure cookies in each browser:
			</p>
			<ul>
			    <li><a href="{externalLink: edgeCookies}">Edge</a></li>
			    <li><a href="{externalLink: chromeCookies}">Google Chrome</a></li>
			    <li><a href="{externalLink: googleAndroidCookies}">Google Android</a></li>
			    <li><a href="{externalLink: internetExplorer8Cookies}">Internet Explorer 8</a></li>
			    <li><a href="{externalLink: internetExplorer9Cookies}">Internet Explorer 9</a></li>
			    <li><a href="{externalLink: internetExplorer11Cookies}">Internet Explorer 11</a></li>
			    <li><a href="{externalLink: firefoxCookies}">Mozilla Firefox</a></li>
			    <li><a href="{externalLink: operaCookies}">Opera</a></li>
			    <li><a href="{externalLink: safariCookies}">Safari</a></li>
			    <li><a href="{externalLink: safariMobileCookies}">Safari Mobile</a></li>
			</ul>

			<h3>1. Types and Uses</h3>
			<p>The service uses own and third-party cookies to improve browsing, provide the service, and obtain statistics on the use of the website.</p>

			<h3>2. What is a cookie and what is it used for?</h3>
			<p>
			    A cookie is a text file that is sent to your computer, mobile, or tablet web browser and is used to store and retrieve information about
			    the browsing done. For example, to remember your username and password or your profile preferences.
			</p>

			<h3>3. What types of cookies are there?</h3>
			<p>According to who manages them:</p>
			<ul>
			    <li>Own cookies; they are sent to your browser from our own equipment or web domains.</li>
			    <li>Third-party cookies; they are sent to your browser from equipment or web domains that we do not directly manage, but by a third entity such as Google.</li>
			</ul>
			<p>Based on how long they remain active, there are:</p>
			<ul>
			    <li>Session cookies; they remain in your browser's cookie file until you leave the website, so none stay on your device.</li>
			    <li>Persistent cookies; they remain on your device and our website reads them every time you make a new visit. These cookies stop working after a specific date.</li>
			</ul>
			<p>Finally, according to their purpose, we have:</p>
			<ul>
			    <li>Technical cookies; they improve browsing and the proper functioning of the website.</li>
			    <li>Personalization cookies; they allow access to the service with predefined characteristics based on a series of criteria.</li>
			    <li>Analytics cookies; they allow measuring and statistically analyzing the use of the provided service.</li>
			</ul>
			<p>List of third-party cookies used by <strong>remove-background.com</strong></p>
			<ul>
			    <li>
			        <a href="{externalLink: googleCookiesPolicy}">Google</a>: Their cookies (NID or CONSENT) allow personalizing how ads appear outside of Google or storing information
			        such as the preferred language when displaying search results.<br><a href="{externalLink: googleCookieTypes}">More information</a> about the types of cookies that Google uses in its various services.
			    </li>
			    <li>
			        <a href="{externalLink: googleAnalyticsCookies}">Google Analytics</a>: Their cookies (such as _ga) allow counting how many times a user visits a website, how, when, and from where.<br>
			        In any case, you can disable Google Analytics cookies from <a href="{externalLink: disableGoogleAnalytics}">here</a>.
			    </li>
			    <li>Cloudflare: To learn about Cloudflare's cookie policy and usage, click <a href="{externalLink: cloudflareCookiesPolicy}">this link</a>.</li>
			    <li>Stripe: Find information <a href="{externalLink: stripeCookiesPolicy}">here</a> about Stripe's cookie policy and usage.</li>
			</ul>
			
		`
	}
};
