import { useState } from 'react';
import { useSelector } from 'react-redux';
import { redirectTo } from '@/pages/private/checkout/Steps/Payment/utils';
import routes from '@/routes';
import stripeModel, { PAYMENT_INTENT_STATUS } from '@/models/stripe';

import { confirmPayment } from './utils';

export function usePaymentUtils() {
	const [newErr, setNewErr] = useState(null);
	const [executingPayment, setExecutingPayment] = useState(false);

	const { stripeSubscriptionId } = useSelector(
		state => state.auth?.user || {}
	);
	const currency = useSelector(state => state.auth.currency);

	function clearError() {
		setNewErr(null);
	}

	async function executePayment(stripe, config = {}) {
		try {
			const {
				priceId,
				clientSecret = null,
				nextAction = 'subscribe'
			} = config;

			if (!priceId) {
				return;
			}

			setExecutingPayment(true);

			if (clientSecret) {
				if (!stripe) {
					return;
				}

				const response = await stripeModel.getPaymentMethodDetails();

				await confirmPayment(stripe, {
					type: response.stripePaymentMethodType,
					stripePaymentMethodId: response.stripePaymentMethodId,
					clientSecret,
					stripeSubscriptionId
				});

				return redirectTo(`${routes.myImages}?success=true`);
			}

			let response = null;

			if (nextAction === 'subscribe') {
				response = await stripeModel.subscribe({
					priceId,
					currency
				});
			} else {
				await stripeModel.switchSubscription(priceId);
				return redirectTo(`${routes.myImages}?redirect=true`);
			}

			if (!response.paymentIntent) {
				return redirectTo(routes.billing);
			}

			if (
				[
					PAYMENT_INTENT_STATUS.REQUIRES_ACTION,
					PAYMENT_INTENT_STATUS.REQUIRES_CONFIRMATION
				].includes(response.paymentIntent.status)
			) {
				await confirmPayment(stripe, {
					type: response.paymentIntent.payment_method.type,
					stripePaymentMethodId: response.paymentIntent.payment_method.id,
					clientSecret: response.paymentIntent.clientSecret,
					stripeSubscriptionId: response.subscription.id
				});
			}

			/*
				Payment intent is succeeded, requires_payment_method, processing, or `stripeModule.confirmPayment` was successful
			*/
			redirectTo(`${routes.myImages}?success=true`);
			setExecutingPayment(false);
		} catch (err) {
			setExecutingPayment(false);
			// 3dSecure 2 failure
			if (err.code === 'payment_intent_authentication_failure') {
				return redirectTo(routes.billing);
			}

			setNewErr(err);
		}
	}

	return {
		executePayment,
		paymentError: newErr,
		executingPayment,
		clearError
	};
}
