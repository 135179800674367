import { SvgIcon } from '@mui/material';

export function CalendarIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M5.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V2.385h1.539V4.5h7.577V2.385h1.5V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H5.308Zm0-1.5h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212v-9.384H5v9.384c0 .077.032.148.096.212a.294.294 0 0 0 .212.096ZM5 8.808h14v-2.5a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H5.308a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v2.5Zm7 5.269a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.626c0-.245.086-.453.258-.626a.853.853 0 0 1 .626-.258c.245 0 .454.086.626.258a.853.853 0 0 1 .258.626.853.853 0 0 1-.258.626.853.853 0 0 1-.626.259Zm-4 0a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.626c0-.245.086-.453.258-.626A.853.853 0 0 1 8 12.308c.245 0 .454.086.626.258a.853.853 0 0 1 .258.626.853.853 0 0 1-.258.626.853.853 0 0 1-.626.259Zm8 0a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.626c0-.245.086-.453.258-.626a.853.853 0 0 1 .626-.258c.245 0 .453.086.626.258a.853.853 0 0 1 .258.626.853.853 0 0 1-.258.626.853.853 0 0 1-.626.259ZM12 18a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.625c0-.245.086-.454.258-.627a.853.853 0 0 1 .626-.258c.245 0 .454.086.626.258a.853.853 0 0 1 .258.627.853.853 0 0 1-.258.625A.853.853 0 0 1 12 18Zm-4 0a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.625c0-.245.086-.454.258-.627A.853.853 0 0 1 8 16.232c.245 0 .454.086.626.258a.853.853 0 0 1 .258.627.853.853 0 0 1-.258.625A.853.853 0 0 1 8 18Zm8 0a.853.853 0 0 1-.626-.259.853.853 0 0 1-.258-.625c0-.245.086-.454.258-.627a.853.853 0 0 1 .626-.258c.245 0 .453.086.626.258a.853.853 0 0 1 .258.627.853.853 0 0 1-.258.625A.853.853 0 0 1 16 18Z"
				fill="#141414"
			/>
		</SvgIcon>
	);
}
