import { FaqAccordions } from './FaqAccordions';
import { Hero } from './Hero';

export function Faq() {
	return (
		<>
			<Hero />
			<FaqAccordions />
		</>
	);
}
