import { SvgIcon } from '@mui/material';

export function PictureFrame(props) {
	return (
		<SvgIcon
			width="222"
			height="222"
			viewBox="0 0 222 222"
			fill="none"
			{...props}
		>
			<path
				d="M49.0962 189.625C44.4237 189.625 40.4688 188.006 37.2313 184.769C33.9938 181.531 32.375 177.576 32.375 172.904V49.0965C32.375 44.424 33.9938 40.469 37.2313 37.2315C40.4688 33.994 44.4237 32.3752 49.0962 32.3752H172.903C177.576 32.3752 181.531 33.994 184.768 37.2315C188.006 40.469 189.625 44.424 189.625 49.0965V172.904C189.625 177.576 188.006 181.531 184.768 184.769C181.531 188.006 177.576 189.625 172.903 189.625H49.0962ZM49.0962 175.75H172.903C173.615 175.75 174.267 175.454 174.86 174.861C175.453 174.268 175.75 173.615 175.75 172.904V49.0965C175.75 48.3848 175.453 47.7325 174.86 47.1394C174.267 46.5465 173.615 46.25 172.903 46.25H49.0962C48.3846 46.25 47.7322 46.5465 47.1392 47.1394C46.5462 47.7325 46.2498 48.3848 46.2498 49.0965V172.904C46.2498 173.615 46.5462 174.268 47.1392 174.861C47.7322 175.454 48.3846 175.75 49.0962 175.75ZM62.4377 154.937H160.273L129.855 114.38L103.884 148.178L85.3844 124.52L62.4377 154.937ZM78.6248 90.1873C81.8267 90.1873 84.5542 89.0607 86.8073 86.8076C89.0605 84.5544 90.187 81.8269 90.187 78.625C90.187 75.4231 89.0605 72.6956 86.8073 70.4425C84.5542 68.1893 81.8267 67.0627 78.6248 67.0627C75.4229 67.0627 72.6954 68.1893 70.4422 70.4425C68.1891 72.6956 67.0625 75.4231 67.0625 78.625C67.0625 81.8269 68.1891 84.5544 70.4422 86.8076C72.6954 89.0607 75.4229 90.1873 78.6248 90.1873Z"
				fill="white"
			/>
		</SvgIcon>
	);
}
