import { SvgIcon } from '@mui/material';

export function SignUpMailIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<svg
				width="24"
				height="24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.20039 19.2C3.70539 19.2 3.28164 19.0237 2.92914 18.671C2.57664 18.3184 2.40039 17.8944 2.40039 17.399V6.59305C2.40039 6.09771 2.57664 5.67505 2.92914 5.32505C3.28164 4.97505 3.70539 4.80005 4.20039 4.80005H19.8004C20.2954 4.80005 20.7191 4.97638 21.0716 5.32905C21.4241 5.68171 21.6004 6.10571 21.6004 6.60105V17.407C21.6004 17.9024 21.4241 18.325 21.0716 18.675C20.7191 19.025 20.2954 19.2 19.8004 19.2H4.20039ZM12.0004 13.2L4.20039 8.72505V17.4H19.8004V8.72505L12.0004 13.2ZM12.0004 11.075L19.8004 6.60005H4.20039L12.0004 11.075ZM4.20039 8.72505V6.60005V17.4V8.72505Z"
					fill="#141414"
				/>
			</svg>
		</SvgIcon>
	);
}
