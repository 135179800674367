import { SvgIcon } from '@mui/material';

export function CompareIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M8.9615 20.5H5.31228C4.81051 20.5 4.38302 20.323 4.02982 19.9689C3.67661 19.6149 3.5 19.1894 3.5 18.6923V5.30772C3.5 4.81061 3.67701 4.38505 4.03102 4.03105C4.38503 3.67703 4.81058 3.50002 5.3077 3.50002H8.9615V5H5.3077C5.21795 5 5.14423 5.02885 5.08653 5.08655C5.02883 5.14425 4.99997 5.21797 4.99997 5.30772V18.6923C4.99997 18.782 5.02883 18.8557 5.08653 18.9134C5.14423 18.9711 5.21795 19 5.3077 19H8.9615V20.5ZM11.25 23V1.19232H12.75V23H11.25ZM15.0385 20.5V18.8846H16.6538V20.5H15.0385ZM15.0385 5.1154V3.50002H16.6538V5.1154H15.0385ZM18.8846 20.5V18.8846H20.5C20.5 19.332 20.3418 19.7131 20.0254 20.0278C19.7091 20.3426 19.3288 20.5 18.8846 20.5ZM18.8846 16.6538V15.0385H20.5V16.6538H18.8846ZM18.8846 12.8077V11.1923H20.5V12.8077H18.8846ZM18.8846 8.96152V7.34617H20.5V8.96152H18.8846ZM18.8846 5.1154V3.50002C19.3288 3.50002 19.7091 3.6582 20.0254 3.97455C20.3418 4.29088 20.5 4.67116 20.5 5.1154H18.8846Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
