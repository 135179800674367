import { createSlice } from '@reduxjs/toolkit';

import {
	handleAsyncThunkActionState,
	initialAsyncState,
	isAsyncThunkActionWithPrefix
} from '../utils';

const initialState = {
	fetchPrices: initialAsyncState,
	fetchPayments: initialAsyncState,
	page: 1
};

const billingSlice = createSlice(
	{
		name: 'billing',
		initialState,
		reducers: {
			clearData() {
				return initialState;
			},
			setPage(state, { payload }) {
				state.page = payload;
			}
		},
		extraReducers(builder) {
			builder.addMatcher(
				isAsyncThunkActionWithPrefix('billing'),
				handleAsyncThunkActionState
			);
		}
	},
	initialState
);

export const { clearData, setPage } = billingSlice.actions;

export default billingSlice.reducer;
