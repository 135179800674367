import { SvgIcon } from '@mui/material';

export function MagicIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m9.951 7.423-.62 2.302a3.686 3.686 0 0 1-2.606 2.606l-2.302.62c-.564.149-.564.95 0 1.098l2.302.62a3.686 3.686 0 0 1 2.606 2.606l.62 2.302c.149.564.95.564 1.098 0l.62-2.302a3.686 3.686 0 0 1 2.606-2.606l2.302-.62c.564-.149.564-.95 0-1.098l-2.302-.62a3.686 3.686 0 0 1-2.606-2.606l-.62-2.302c-.149-.564-.95-.564-1.098 0Zm.549 3.475a5.188 5.188 0 0 0 2.602 2.602 5.188 5.188 0 0 0-2.602 2.602A5.187 5.187 0 0 0 7.898 13.5a5.187 5.187 0 0 0 2.602-2.602ZM16.747 4.195l-.287 1.063a1.698 1.698 0 0 1-.878 1.079h-.002a1.705 1.705 0 0 1-.322.123l-1.063.287c-.26.068-.26.438 0 .506l1.063.287a1.715 1.715 0 0 1 .322.122l.002.001a1.7 1.7 0 0 1 .755.755v.002a1.697 1.697 0 0 1 .123.322l.287 1.063c.068.26.438.26.506 0l.287-1.063a1.7 1.7 0 0 1 .122-.322l.001-.002a1.702 1.702 0 0 1 .755-.755h.002a1.705 1.705 0 0 1 .322-.123l1.063-.287c.26-.068.26-.438 0-.506l-1.063-.287a1.722 1.722 0 0 1-.322-.122l-.002-.001a1.7 1.7 0 0 1-.755-.755V5.58a1.697 1.697 0 0 1-.123-.322l-.287-1.063c-.068-.26-.438-.26-.506 0Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
