import { SvgIcon } from '@mui/material';

export function SignOutIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M5.30775 20.5C4.80258 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30775C3.5 4.80258 3.675 4.375 4.025 4.025C4.375 3.675 4.80258 3.5 5.30775 3.5H12.0095V5H5.30775C5.23075 5 5.16025 5.03208 5.09625 5.09625C5.03208 5.16025 5 5.23075 5 5.30775V18.6923C5 18.7692 5.03208 18.8398 5.09625 18.9038C5.16025 18.9679 5.23075 19 5.30775 19H12.0095V20.5H5.30775ZM16.2308 16.2692L15.1923 15.1845L17.627 12.75H9.09625V11.25H17.627L15.1923 8.8155L16.2308 7.73075L20.5 12L16.2308 16.2692Z"
				fill="#656565"
			/>
		</SvgIcon>
	);
}
