import { SvgIcon } from '@mui/material';

export function ImagesIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19ZM6.75003 16.75H17.3269L14.0384 12.3654L11.2308 16.0192L9.23075 13.4616L6.75003 16.75ZM8.49998 9.74995C8.84613 9.74995 9.14099 9.62816 9.38458 9.38458C9.62816 9.14099 9.74995 8.84613 9.74995 8.49998C9.74995 8.15383 9.62816 7.85896 9.38458 7.61538C9.14099 7.37179 8.84613 7.25 8.49998 7.25C8.15383 7.25 7.85896 7.37179 7.61538 7.61538C7.37179 7.85896 7.25 8.15383 7.25 8.49998C7.25 8.84613 7.37179 9.14099 7.61538 9.38458C7.85896 9.62816 8.15383 9.74995 8.49998 9.74995Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
