export const ALL_CANVAS_WRAPPER_ID = 'all-canvas-container';
export const CANVAS_WIDTH_MAX = 485;
export const CANVAS_WIDTH_MIN = 280;
export const BRUSH_COLOR = 'rgba(255,255,255,0.6)';
export const TOOLBAR_COLOR_BUTTONS_SIZE = 40;
export const TOOLBAR_BG_BUTTONS_SIZE = 56;
export const IMAGES_LIMIT = 7;
export const CUSTOM_FABRIC_EVENTS = {
	mainBackgroundAdded: 'main:backgroundAdded'
};
export const LOW_QUALITY_MAX_SIZE = 500;
export const MAX_DALLE_PROMPT_LENGTH = 1000;
export const BACKGROUND_ROWS_QUANTITY = 10;
export const COLOR_ROWS_QUANTITY = 11;
export const BULK_IMAGES_LIMIT = 15;
export const DROPZONE_ERRORS = {
	tooManyFiles: 'too-many-files',
	fileInvalidType: 'file-invalid-type',
	fileTooLarge: 'file-too-large'
};
export const BLUR_MAX_VALUE = 30;
export const BASE_BG_ID = 'base';
export const MIN_ZOOM = 1;
export const MAX_ZOOM = 2;
