import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function Scroller() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [pathname]);
}
