import { SvgIcon } from '@mui/material';

export function SparkleIcon(props) {
	return (
		<SvgIcon
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="m22.19 9.01-1.479 5.488a8.79 8.79 0 0 1-6.213 6.213l-5.488 1.48c-1.347.355-1.347 2.263 0 2.618l5.488 1.48a8.79 8.79 0 0 1 6.213 6.213l1.48 5.488c.355 1.347 2.263 1.347 2.618 0l1.48-5.488a8.79 8.79 0 0 1 6.213-6.213l5.488-1.48c1.347-.355 1.347-2.263 0-2.618l-5.488-1.48a8.79 8.79 0 0 1-6.213-6.213l-1.48-5.488c-.355-1.347-2.263-1.347-2.618 0Zm1.31 8.285a12.369 12.369 0 0 0 6.205 6.205 12.369 12.369 0 0 0-6.205 6.205 12.369 12.369 0 0 0-6.205-6.205 12.369 12.369 0 0 0 6.205-6.205Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
