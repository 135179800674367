import { SvgIcon } from '@mui/material';

export function DotsMenuIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M11.65 20c-.45 0-.847-.165-1.166-.484A1.604 1.604 0 0 1 10 18.349c0-.45.165-.847.484-1.166.32-.32.715-.484 1.167-.484.45 0 .847.165 1.166.484.32.32.484.715.484 1.166 0 .451-.165.848-.484 1.167-.32.319-.715.484-1.166.484Zm0-6.35c-.45 0-.847-.164-1.166-.484A1.604 1.604 0 0 1 10 12c0-.451.165-.847.484-1.166.32-.32.715-.485 1.167-.485.45 0 .847.165 1.166.485.32.319.484.715.484 1.166 0 .451-.165.847-.484 1.166-.32.32-.715.485-1.166.485Zm0-6.349c-.45 0-.847-.165-1.166-.484A1.604 1.604 0 0 1 10 5.651c0-.452.165-.848.484-1.167.32-.319.715-.484 1.167-.484.45 0 .847.165 1.166.484.32.32.484.715.484 1.167 0 .45-.165.847-.484 1.166-.32.32-.715.484-1.166.484Z"
				fill="#141414"
			/>
		</SvgIcon>
	);
}
