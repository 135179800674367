import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/Button';
import { AddIcon } from '@/components/Icons';
import { useMedia } from '@/hooks/responsive';

export function EmptyState({ onClick }) {
	const { t } = useTranslation();
	const mdDown = useMedia('mdDown');

	return (
		<Box
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: mdDown ? '20vh' : '30vh'
			}}
		>
			<Typography fontWeight="semi">
				{t('users.emptyState.title')}
			</Typography>
			<Typography color="text.secondary" variant="body0">
				{t('users.emptyState.subtitle')}
			</Typography>
			<Button
				startIcon={<AddIcon />}
				sx={{ marginTop: 2.5 }}
				variant="outlined"
				onClick={onClick}
			>
				{t('users.buttonAddUser')}
			</Button>
		</Box>
	);
}
