import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { getAmountByCredit } from '@/utils/plans';
import { formatPrice } from '@/utils';

export function TrialPlan({ trialPlan, trialPlanPrice, currency, discount }) {
	const { t } = useTranslation();

	return (
		<>
			<Box py={2}>
				<Stack flexDirection="row" justifyContent="space-between">
					<Box>
						<Typography variant="body1">
							{t('plans.creditsPlan', {
								quantity: trialPlan.credits ?? 0
							})}
						</Typography>
						<Typography variant="body0" color="text.secondary">
							{t('plans.priceByCredit', {
								formattedPrice: formatPrice({
									amount: getAmountByCredit(trialPlan),
									currency: currency || trialPlan.currency,
									fraction: 1
								})
							})}
						</Typography>
					</Box>

					<Stack direction="row" alignItems="center" spacing={1}>
						{!!discount && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								sx={theme => ({
									background: theme.palette.text.primary,
									px: 0.5,
									height: 20,
									borderRadius: 1
								})}
							>
								<Typography
									variant="body0"
									fontWeight={700}
									color="background.default"
								>
									{t('common.discount', {
										discount
									})}
								</Typography>
							</Box>
						)}
						<Typography
							variant="body3"
							fontSize={{ xs: 20, sm: 24 }}
							fontWeight={700}
						>
							{trialPlanPrice}
						</Typography>
					</Stack>
				</Stack>
			</Box>
			<Divider orientation="horizontal" />
		</>
	);
}
