import { SvgIcon } from '@mui/material';

export function RedoIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M9.62088 18.5C8.15808 18.5 6.9052 18.0102 5.86225 17.0307C4.81932 16.0512 4.29785 14.8455 4.29785 13.4134C4.29785 11.9814 4.81932 10.7772 5.86225 9.80095C6.9052 8.82468 8.15808 8.33655 9.62088 8.33655H16.6421L13.8594 5.55383L14.9132 4.5L19.4997 9.08652L14.9132 13.673L13.8594 12.6192L16.6421 9.8365H9.62088C8.57729 9.8365 7.67922 10.1795 6.92665 10.8654C6.17408 11.5513 5.7978 12.4006 5.7978 13.4134C5.7978 14.4262 6.17408 15.2772 6.92665 15.9663C7.67922 16.6554 8.57729 17 9.62088 17H16.7978V18.5H9.62088Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
