import { Badge } from '@/components/Badge';
import { Card } from '@/components/Card';
import { PlanPrice } from '@/components/PlanPrice';

import { StepIndicator } from '@/components/StepIndicator';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CARD_MUI_PADDING = 3;

export function Plan({
	badgeLabel,
	content,
	title,
	credits = 0,
	price,
	prices,
	action,
	type,
	sx,
	hasCurrencySelector,
	...props
}) {
	const { t } = useTranslation();
	return (
		<Card
			sx={{
				p: CARD_MUI_PADDING,
				width: '100%',
				borderRadius: '8px',
				maxWidth: '388px',
				display: 'flex',
				flexDirection: 'column',
				...sx
			}}
			{...props}
		>
			{Boolean(badgeLabel) && (
				<Box
					display="flex"
					justifyContent="end"
					sx={{
						position: 'relative',
						top: `-${CARD_MUI_PADDING * 8 + 16}px`
					}}
				>
					<Badge label={badgeLabel} />
				</Box>
			)}
			<StepIndicator
				label={t(`common.${type}`)}
				sx={{
					mb: 2,
					display: 'inline-flex',
					alignSelf: 'flex-start'
				}}
			/>
			<Box mb={0.5}>
				<Typography variant="body3" fontSize={20}>
					{title}
				</Typography>
				<Typography variant="body3" component="p" fontSize={16}>
					({t('plans.creditsPlan', { quantity: credits })})
				</Typography>
			</Box>
			<PlanPrice
				{...price}
				sx={{
					mb: 2
				}}
			/>
			<Box>{content}</Box>
			{action && (
				<Box pt={4} mt="auto">
					{action}
				</Box>
			)}
		</Card>
	);
}
