import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
	minutes: dayjs().minute()
};

const myImagesSlice = createSlice(
	{
		name: 'myImages',
		initialState,
		reducers: {
			reloadMinutes(state) {
				state.minutes = dayjs().minute();
			}
		}
	},
	initialState
);

export const { reloadMinutes } = myImagesSlice.actions;

export default myImagesSlice.reducer;
