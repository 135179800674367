export const AEPD_URL = {
	es: 'https://www.aepd.es/',
	en: 'https://www.aepd.es/en'
};

export const KNOW_YOUR_RIGHTS_URL = {
	es: 'https://www.aepd.es/derechos-y-deberes/conoce-tus-derechos',
	en: 'https://www.aepd.es/en/rights-and-duties/know-your-rights'
};

export const EXTERNAL_LINKS = {
	googleAnalyticsCookies:
		'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es-419',
	disableGoogleAnalytics: 'https://tools.google.com/dlpage/gaoptout',
	edgeCookies:
		'https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd',
	chromeCookies: 'https://support.google.com/chrome/answer/95647?hl=en',
	googleAndroidCookies:
		'https://support.google.com/chrome/answer/114662?hl=en-GB&visit_id=1-636664625754483085-1648636281&rd=1&co=GENIE.Platform%3DAndroid',
	internetExplorer8Cookies:
		'https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d',
	internetExplorer9Cookies:
		'https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc',
	internetExplorer11Cookies:
		'https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc',
	firefoxCookies:
		'https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox',
	operaCookies: 'https://help.opera.com/en/latest/web-preferences/#cookies',
	safariCookies: 'https://support.apple.com/kb/ph21411?locale=es_ES',
	safariMobileCookies: 'https://support.apple.com/es-es/105082',
	googleCookiesPolicy: 'https://policies.google.com/privacy',
	googleCookieTypes:
		'https://policies.google.com/technologies/cookies#types-of-cookies',
	cloudflareCookiesPolicy: 'https://www.cloudflare.com/privacypolicy/',
	stripeCookiesPolicy: 'https://stripe.com/es-us/legal/cookies-policy'
};
