import { Form } from '@/pages/public/contact/Form.jsx';
import { Hero } from './Hero.jsx';

export function Contact() {
	return (
		<>
			<Hero />
			<Form />
		</>
	);
}
