import { SvgIcon } from '@mui/material';

export function MailIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
			{...props}
		>
			<svg
				width="24"
				height="24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M4.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v11.384c0 .505-.175.933-.525 1.283-.35.35-.778.525-1.283.525H4.308ZM12 12.558 4 7.442v10.25a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087h15.385a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.22V7.442l-8 5.115ZM12 11l7.846-5H4.154L12 11ZM4 7.442V6v11.692a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087H4V7.442Z"
					fill="currentColor"
				/>
			</svg>
		</SvgIcon>
	);
}
