import { createSlice } from '@reduxjs/toolkit';

import * as thunks from './thunks';
import {
	handleAsyncThunkActionState,
	isAsyncThunkActionWithPrefix
} from '../utils';

import { STEPS } from './constants';

export { STEPS };

const initialState = {
	prices: [],
	priceId: null,
	step: STEPS.SELECT_PLAN,
	initializing: false,
	vatVisible: false,
	fetchingPrices: false,
	relevantBillingData: {
		country: '',
		accountType: ''
	},
	nextAction: null,
	clientSecret: null,
	userCountry: null,
	fixedErrorCode: null
};

const checkoutSlice = createSlice(
	{
		name: 'checkout',
		initialState,
		reducers: {
			setPlan(state, action) {
				state.priceId = action.payload;
			},
			setStep(state, action) {
				state.step = action.payload;
			},
			setRelevantBillingData(state, action) {
				state.relevantBillingData = {
					...state.relevantBillingData,
					...action.payload
				};
			},
			clearData(state) {
				Object.assign(state, initialState);
			}
		},
		extraReducers(builder) {
			builder.addCase(thunks.changePlan.fulfilled, (state, action) => {
				const { nextAction, priceId } = action.payload;

				state.nextAction = nextAction;
				state.priceId = priceId;
			});
			builder.addCase(thunks.getPrices.fulfilled, (state, action) => {
				const { userCountry, prices } = action.payload;

				if (userCountry) {
					state.userCountry = userCountry;
				}

				state.prices = prices;
			});
			builder.addCase(thunks.initCheckout.fulfilled, (state, action) => {
				const {
					priceId,
					nextAction,
					clientSecret,
					userCountry,
					activePrices,
					step,
					relevantBillingData = {}
				} = action.payload;

				if (userCountry) {
					state.userCountry = userCountry;
				}

				if (priceId) {
					state.priceId = priceId;
				}

				if (clientSecret) {
					state.clientSecret = clientSecret;
				}

				if (relevantBillingData) {
					state.relevantBillingData = {
						...(state?.relevantBillingData || {}),
						...relevantBillingData
					};
				}

				state.nextAction = nextAction;
				state.prices = activePrices;
				state.step = step;
			});

			builder.addMatcher(
				isAsyncThunkActionWithPrefix('checkout'),
				handleAsyncThunkActionState
			);
		}
	},
	initialState
);

export const { setStep, setPlan, clearData, setRelevantBillingData } =
	checkoutSlice.actions;

export default checkoutSlice.reducer;
