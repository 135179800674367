import { SvgIcon } from '@mui/material';

export function GroupOfUsersIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M.5 17.79v-1.22c0-.665.347-1.212 1.042-1.64.695-.427 1.601-.64 2.718-.64.184 0 .37.005.557.017.187.011.376.034.566.067a3.734 3.734 0 0 0-.575 2.002v1.413H.5Zm6 0v-1.376c0-.468.131-.896.394-1.283.263-.388.642-.726 1.137-1.015.495-.288 1.08-.505 1.754-.649a10.574 10.574 0 0 1 2.211-.216c.816 0 1.56.072 2.235.216.674.144 1.259.36 1.754.65.495.288.87.626 1.128 1.014.258.387.387.815.387 1.283v1.375h-11Zm12.692 0v-1.411c0-.376-.044-.73-.133-1.064a3.635 3.635 0 0 0-.401-.941c.196-.034.383-.056.562-.067.18-.012.356-.018.53-.018 1.117 0 2.02.212 2.712.634.692.422 1.038.971 1.038 1.647v1.22h-4.308Zm-11.115-1.5h7.861v-.106c-.102-.404-.528-.744-1.277-1.02-.748-.275-1.635-.413-2.661-.413s-1.913.138-2.662.413c-.748.276-1.169.616-1.261 1.02v.105Zm-3.82-2.972c-.471 0-.874-.168-1.208-.503a1.65 1.65 0 0 1-.501-1.208c0-.477.168-.88.503-1.209a1.664 1.664 0 0 1 1.209-.493c.477 0 .881.164 1.213.493.332.329.498.733.498 1.212 0 .464-.164.865-.493 1.202a1.637 1.637 0 0 1-1.221.506Zm15.493 0c-.467 0-.869-.168-1.206-.506a1.642 1.642 0 0 1-.506-1.202c0-.48.17-.883.506-1.212a1.67 1.67 0 0 1 1.208-.493c.482 0 .887.164 1.216.493.33.329.494.732.494 1.209 0 .47-.165.873-.492 1.208a1.638 1.638 0 0 1-1.22.503Zm-7.746-.817a2.51 2.51 0 0 1-1.84-.757 2.5 2.5 0 0 1-.76-1.84c0-.735.252-1.351.757-1.849A2.522 2.522 0 0 1 12 7.31c.736 0 1.352.248 1.85.745.497.497.746 1.113.746 1.847a2.53 2.53 0 0 1-.745 1.84c-.497.507-1.113.76-1.848.76Zm0-1.5c.307 0 .565-.107.776-.321a1.07 1.07 0 0 0 .316-.78c0-.306-.105-.564-.315-.775-.21-.211-.47-.316-.781-.316-.303 0-.56.105-.775.315a1.05 1.05 0 0 0-.321.78c0 .303.107.562.321.776.214.214.474.32.78.32Z"
				fill="#141414"
			/>
		</SvgIcon>
	);
}
