import { SvgIcon } from '@mui/material';

export function CreditCardIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M21.5 6.3077V17.6923C21.5 18.1974 21.325 18.625 20.975 18.975C20.625 19.325 20.1974 19.5 19.6923 19.5H4.3077C3.80257 19.5 3.375 19.325 3.025 18.975C2.675 18.625 2.5 18.1974 2.5 17.6923V6.3077C2.5 5.80257 2.675 5.375 3.025 5.025C3.375 4.675 3.80257 4.5 4.3077 4.5H19.6923C20.1974 4.5 20.625 4.675 20.975 5.025C21.325 5.375 21.5 5.80257 21.5 6.3077ZM3.99998 8.40385H20V6.3077C20 6.23077 19.9679 6.16024 19.9038 6.09613C19.8397 6.03203 19.7692 5.99998 19.6923 5.99998H4.3077C4.23077 5.99998 4.16024 6.03203 4.09613 6.09613C4.03202 6.16024 3.99998 6.23077 3.99998 6.3077V8.40385ZM3.99998 11.5961V17.6923C3.99998 17.7692 4.03202 17.8397 4.09613 17.9038C4.16024 17.9679 4.23077 18 4.3077 18H19.6923C19.7692 18 19.8397 17.9679 19.9038 17.9038C19.9679 17.8397 20 17.7692 20 17.6923V11.5961H3.99998Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
