import { SvgIcon } from '@mui/material';

export function ReceiptLongIcon(props) {
	return (
		<SvgIcon
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			{...props}
		>
			<path
				d="M5.99997 21.5C5.30128 21.5 4.70993 21.258 4.22595 20.7741C3.74198 20.2901 3.5 19.7002 3.5 19.0046V16.5001H6.5V2.69238L7.88462 3.88468L9.28845 2.69238L10.6923 3.88468L12.0961 2.69238L13.5 3.88468L14.9038 2.69238L16.3077 3.88468L17.7115 2.69238L19.1153 3.88468L20.5 2.69238V19C20.5 19.6987 20.258 20.2901 19.774 20.7741C19.29 21.258 18.6987 21.5 18 21.5H5.99997ZM18 20C18.2833 20 18.5208 19.9042 18.7125 19.7125C18.9041 19.5209 19 19.2834 19 19V5.00003H7.99998V16.5001H17V19C17 19.2834 17.0958 19.5209 17.2875 19.7125C17.4791 19.9042 17.7166 20 18 20ZM9.1923 8.75001V7.25006H14.8654V8.75001H9.1923ZM9.1923 11.75V10.2501H14.8654V11.75H9.1923ZM16.9423 8.88463C16.6974 8.88463 16.4888 8.79842 16.3163 8.62598C16.1439 8.45355 16.0577 8.2449 16.0577 8.00003C16.0577 7.75517 16.1439 7.54652 16.3163 7.37408C16.4888 7.20165 16.6974 7.11543 16.9423 7.11543C17.1871 7.11543 17.3958 7.20165 17.5682 7.37408C17.7407 7.54652 17.8269 7.75517 17.8269 8.00003C17.8269 8.2449 17.7407 8.45355 17.5682 8.62598C17.3958 8.79842 17.1871 8.88463 16.9423 8.88463ZM16.9423 11.8846C16.6974 11.8846 16.4888 11.7984 16.3163 11.626C16.1439 11.4536 16.0577 11.2449 16.0577 11C16.0577 10.7552 16.1439 10.5465 16.3163 10.3741C16.4888 10.2016 16.6974 10.1154 16.9423 10.1154C17.1871 10.1154 17.3958 10.2016 17.5682 10.3741C17.7407 10.5465 17.8269 10.7552 17.8269 11C17.8269 11.2449 17.7407 11.4536 17.5682 11.626C17.3958 11.7984 17.1871 11.8846 16.9423 11.8846ZM5.99997 20H15.5V18H4.99997V19C4.99997 19.2834 5.09581 19.5209 5.28747 19.7125C5.47914 19.9042 5.71664 20 5.99997 20Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
