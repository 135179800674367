import { forwardRef } from 'react';
import { Select as MuiSelect } from '@mui/material';
import { ChevronDownIcon } from '../Icons';

export const Select = forwardRef(({ sx, children, ...props }, ref) => {
	return (
		<MuiSelect
			ref={ref}
			displayEmpty
			variant="standard"
			IconComponent={ChevronDownIcon}
			sx={{
				color: 'inherit',
				'.MuiSelect-icon': {
					color: 'inherit'
				},
				':after': {
					display: 'none'
				},
				...sx
			}}
			{...props}
		>
			{children}
		</MuiSelect>
	);
});

Select.displayName = 'Select';
